import { createRouter, createWebHistory } from 'vue-router'
const Layout = () => import('@/pages/layout.vue')
const Home = () => import('@/pages/Home')
const GoodsList = () => import('@/pages/Goods/goodsList.vue')
const GoodDetail = () => import('@/pages/Goods/goodDetail.vue')
const DownloadsList = () => import('@/pages/Downloads')
const Search = () => import('@/pages/Search')
const ContactUs = () => import('@/pages/ContactUs')
const UsbT = () => import('@/pages/UsbT')

export default createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: 'home',
          component: Home
        },
        {
          path: 'goods/:_id',
          component: GoodDetail,
          meta: { navThemeModel: 'dark' }
        },
        {
          path: 'goods',
          name: 'goods',
          component: GoodsList
        },
        {
          path: 'search',
          name: 'search',
          component: Search,
          meta: { navThemeModel: 'dark' }
        },
        {
          path: 'downloads',
          component: DownloadsList
        },
        {
          path: 'contactUs',
          component: ContactUs
        },
        {
          path: '',
          redirect: 'home'
        }
      ]
    },

    /**参数架、HID内置U盘超链页面 */
    {
      path: '/usb-t',
      component: UsbT
    }
  ]
})
