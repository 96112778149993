import { createStore } from 'vuex'

export default createStore({
  state: {
    goodTags: [],
    website: {},
    ignoreCheckBrower: false,
  },
  mutations: {
    setIgnoreCheckBrower: (state, value) => {
      state.ignoreCheckBrower = value
    },
    setWebsite: (state, website) => {
      state.website = website
    },
    setGoodTags: (state, goodTags) => {
      state.goodTags = goodTags;
    },
  }
})
