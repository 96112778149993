<template>
  <router-view/>
</template>

<script>
export default {}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html, body {
  height: 100%;
  background-color: #f5f5f5;
  font-family: "DM Sans","Gilroy",-apple-system,"Sofia Pro","Microsoft YaHei","Helvetica","Arial",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
}
#app {
  min-width: 1200px;
  height: 100%;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: #333;
}
a:active,
a:hover,
a:focus {
  outline: 0;
}
ul, li {
  list-style: none;
}
button, input {
  border: none;
  outline: none;
}
.f-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
