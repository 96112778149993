import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import './theme/style.scss'

import App from './App.vue'
import store from './store'
import router from './router'
const app = createApp(App)

app.use(ElementPlus)
app.use(store)
app.use(router)

app.directive('href', (el, binding) => {
  el.href = new URL(binding.value).toString()
})

app.mount('#app')
